<template>
  <div id="login">

    <router-view/>
  </div>
</template>

<script>
 
  require('@/assets/admin/css/font-awesome.min.css');
  require('@/assets/admin/css/simple-line-icons.css');
  require('@/assets/admin/css/style.css');

</script>

